<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card>
          <template v-slot:headerTitle >
              <!-- <b-col sm="12" md="6" lg="6" xl="6"> -->
              <h4 class="card-title py-3">
                <i class="fa fa-map-signs" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>&nbsp;
                {{cvCardTitle}}
                <span class="btn_in_header">
                  <span class="pull-right mb-2">
                    <b-button on class="mr-2" variant="primary" @click="goToAffiliateConsole" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11116'">{{cvbtnMangeAffCodes}}</b-button>
                  </span>
                  <span class="pull-right mb-2">
                    <b-button class="mr-2" variant="primary" @click="showCreateSession" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11116'">{{cvbtnAddNew}}</b-button>
                  </span>
                </span>
              </h4>
              <!-- </b-col> -->
              <!-- <b-col sm="12" md="6" lg="6" xl="6">
                <b-button class="mr-2" variant="primary" @click="showCreateSession" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">{{cvbtnAddNew}}</b-button>
                <b-button class="mr-2" variant="primary" @click="goToAffiliateConsole" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">{{cvbtnMangeAffCodes}}</b-button>
              </b-col> -->
          </template>
          <template v-slot:body>
            <b-row class="row w-100" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11116'">
              <b-col sm="3" md="3" lg="3" xl="3" class="mb-3">
                <!-- Frontend Search -->
                <!-- <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>Frontend Search -->

                  <!--Backend Search  -->
                  <input class="form-control"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchFromBackend"
                  :placeholder="cvSearchText" /><!--Backend Search  -->
              </b-col>

              <!-- Counsellor Name Filter -->
              <b-col sm="2" md="2" lg="2" xl="3" class="mb-2" v-if="userData.user_role == 'USERROLE11111'">
                <select v-model="whereFilter.user_id" class="form-control" @change="setCounsellor" v-if="counsellorObjList.length > 0" title="Counselor List">
                  <option>Counselor List</option>
                  <option v-for="(counsellor, index) of counsellorObjList" :key="(index+1)" :value="counsellor.user_id">
                    {{counsellor.user_name}}
                  </option>
                </select>
              </b-col><!-- Counsellor Name Filter -->

              <!-- Session State Filter -->
              <b-col sm="3" md="3" lg="3" xl="3" class="mb-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11116'">
                <select v-model="whereFilter.session_state" class="form-control" @change="setSessionState" title="session states">
                  <option>GPath Workflow States</option>
                  <option v-for="(sessionState, index) of GPathStateObj" :key="(index+1)" :value="index">
                    {{sessionState}}
                  </option>
                </select>
              </b-col><!-- Session State Filter -->

              <b-col sm="3" md="3" lg="3" xl="3" class="pr-0 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11116'">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getgpath_sessionDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row class="row w-100">
              <b-col sm="12" md="12" lg="12" xl="12" class="pr-0 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11116'">
                <VueJsonToCsv :json-data="gpath_sessionObjList" class="pull-right" :csv-title="csvTitle" v-if="gpath_sessionObjList && gpath_sessionObjList.length > 0">
                  <b-btn variant="primary">CSV</b-btn>
                </VueJsonToCsv>
              </b-col>
            </b-row>
            <b-row :key="currentBatchNo">
              <b-col class="container_minheight" md="12" v-if="gpath_sessionObjList && gpath_sessionObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_sessionObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(user_name)="data">
                    <span class="pointer" @click="openUserProfile(data.item.user_id)">{{ data.item.user_name }}</span><br>
                    <span><small>
                      Id:&nbsp;{{ data.item.user_id }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                      Email:&nbsp;{{ data.item.user_email }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_email)"></i><br><br>
                      Class&nbsp;{{ data.item.class_name }}<br>
                      {{ data.item.org_name }}<br>
                      {{data.item.org_city ? data.item.org_city : '' }}
                      {{data.item.org_state ? ', ' + data.item.org_state : '' }}
                      {{data.item.org_country ? ', ' + data.item.org_country : '' }}<br>
                    </small></span>
                    <div v-if="userData.user_role == 'USERROLE11111'">
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_sessionEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="showGPathSessionDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="generateReport(data.item, false)" size="sm" title="Generate Report"><i class="fa fa-refresh" aria-hidden="true"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="generateReport(data.item, true)" size="sm" title="Generate Report Lambda"><i class="ri-restart-fill"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_sendMail('MODAL', data.item)" title="Send Mail"><i class="fa fa-envelope-o"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="openInBrowser('REPORT', data.item)" title="Open Report in Browser"><i class="fa fa-chrome"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="showPaymentList(data.item)" title="User Payment Transaction"><i class="fa fa-money"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(data.item.report)" v-if="data.item.report" size="sm" title="View GPaths Report"><i class="fa fa-map-signs" aria-hidden="true"></i></b-button><br>
                      <b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" size="md" @click="openInBrowser('CAREER', data.item)" title="Analyse Career Assessment Responses">C</b-badge>&nbsp;&nbsp;
                      <b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="openInBrowser('APTITUDE', data.item)" title="Analyse Aptitude Assessment Responses">A</b-badge>&nbsp;&nbsp;
                      <b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="openInBrowser('SKILL', data.item)" title="Analyse Skill Assessment Responses">S</b-badge>&nbsp;&nbsp;
                      <b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="openInBrowser('EXTRACURRICULAR', data.item)" title="Analyse Extracurricular Assessment Responses">E</b-badge>&nbsp;&nbsp;
                    </div>
                    <div v-else-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11116'">
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="generateReport(data.item, false)" size="sm" title="Generate Report"><i class="fa fa-refresh" aria-hidden="true"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_sendMail('MODAL', data.item)" title="Send Mail"><i class="fa fa-envelope-o"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="openInBrowser('REPORT', data.item)" title="Open Report in Browser"><i class="fa fa-chrome"></i></b-button>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(data.item.report)" v-if="data.item.report" size="sm" title="View GPaths Report"><i class="fa fa-map-signs" aria-hidden="true"></i></b-button><br>
                      <b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="openInBrowser('CAREER', data.item)" title="Analyse Career Assessment Responses">C</b-badge>&nbsp;&nbsp;
                      <b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="openInBrowser('APTITUDE', data.item)" title="Analyse Aptitude Assessment Responses">A</b-badge>&nbsp;&nbsp;
                      <b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="openInBrowser('SKILL', data.item)" title="Analyse Skill Assessment Responses">S</b-badge>&nbsp;&nbsp;
                      <b-badge style="vertical-align: middle; color: #FFF; background: #e5252c !important;" class="pointer" @click="openInBrowser('EXTRACURRICULAR', data.item)" title="Analyse Extracurricular Assessment Responses">E</b-badge>&nbsp;&nbsp;
                    </div>
                    <div v-else>
                      <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(data.item.report)" v-if="data.item.report" size="sm" title="View GPaths Report"><i class="fa fa-map-signs" aria-hidden="true"></i></b-button>
                    </div>
                  </template>

                  <template v-slot:cell(parent_data)="data">
                    <span class="pointer" @click="openUserProfile(data.item.user_id)">{{ data.item.parent_data | is_empty}}</span><br>
                  </template>

                  <template v-slot:cell(invoice_url)="data">
                    <!-- Invoice -->
                    <small>
                      Inv:&nbsp;<span class="success-color" @click="openInvoice(data.item.invoice_url)">{{ data.item.pay_tid ? data.item.pay_tid : " - " }}</span>&nbsp;
                      <i v-if="data.item.invoice_url" class="fa fa-solid fa-credit-card m-0" @click="openInvoice(data.item.invoice_url)" title="Invoice"></i>
                    </small><br/>
                    <!-- Invoice -->

                    <!-- Discount Code -->
                    <small>
                      Code:&nbsp;
                      <span v-if="data.item.aff_code" style="vertical-align: middle; color: #FFF; background: #e5252c !important;">
                        {{ data.item.aff_code ? data.item.aff_code : " - " }}
                      </span>
                    </small><br/><!-- Discount Code -->

                    <!-- Counsellor Name -->
                    <small>
                      Counsellor:&nbsp;
                      <span v-if="data.item.counsellor_name">{{ data.item.counsellor_name }}</span>&nbsp;
                      <i v-if="data.item.counsellor_id" class="fa fa-solid ri-user-star-fill" title="Change Counsellor" @click="showChangeCounsellor(data.item)"></i>
                    </small><br/><!-- Counsellor Name -->
                  </template>

                  <template v-slot:cell(session_state)="data">
                    <small>
                      <b-badge :style="getStateColorCode(data.item.session_state)" class="mt-2">
                        {{ GPathStateObj[data.item.session_state] }}
                      </b-badge><br>
                      Id: {{ data.item.gps_id.slice(0, 10)+"..." }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.gps_id)"></i>
                    </small>
                  </template>

                  <template v-slot:cell(created_on)="data">
                    {{ data.item.created_on | dateFormat }}
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                We did not find any students. <span class="primary-color pointer" @click="showCreateSession">Click here</span>&nbsp;to invite your students to take GPaths: Futuristic Career Interest Assessment
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Gpaths Create Your Custom Session -->
    <b-modal
      v-model="showSessionCreationModal"
      scrollable
      :title="cvModalTitle"
      size="lg"
    >
      <InviteUser :propProductCode="GideProducts.GPATHS" :propProductName="GideProducts.GPATHS" :propOpenedInModal="true" :propCounsellor="userData" @emitCloseInviteUser="emitCloseInviteUser" />
      <template #modal-footer="">
        <b-button size="sm" @click="emitCloseInviteUser()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>
    <!-- Gpaths Create Your Custom Session  -->

    <!-- Allot student a different counsellor -->
    <b-modal
      v-model="showChangeCounsellorModal"
      scrollable
      :title="cvChangeCounsellorModalTitle"
      size="lg"
    >
      <ChangeCounsellor :propOpenedInModal="true" :propGpathUser="propGpathUser" @emitCloseChangeCounselllor="emitCloseChangeCounselllor" />
      <template #modal-footer="">
        <b-button size="sm" @click="emitCloseChangeCounselllor()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>
    <!-- Allot student a different counsellor -->

    <!-- GPathSessionEdit -->
    <b-modal
      v-model="showModelGPathSessionEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <GPathSessionEdit :propOpenedInModal="true" :propGPathSessionObj="gpath_sessionEditObj" @emitCloseGPathSessionEditModal="closeGPathSessionEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeGPathSessionEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- GPathSessionEdit -->

    <!-- SendMail -->
    <b-modal
      v-model="showModelSendMail"
      scrollable
      :title="cvSendMailModalHeader"
    >
      <SendMail :propOpenedInModal="true" :propSendMailObj="sendMailObj" @emitCloseGPathSendMailModal="emitCloseGPathSendMailModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="emitCloseGPathSendMailModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- SendMail -->

    <b-modal v-model="showModelGPathSessionDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGPathSessionDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_sessionDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { GPathSessions } from "../../../FackApi/api/GPathSession.js"
import InviteUser from "../../../components/InviteUser.vue"
import ChangeCounsellor from "../SPCM/SpcmChangeCounsellor.vue"
import GPathSessionEdit from "./GPathSessionEdit.vue"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import GPathData from "../../../FackApi/json/GPath.json"
import SendMail from "../../../components/SendMail.vue"
import SendMailModules from "../../../FackApi/json/SendMailModules.json"
import { User } from "../../../FackApi/api/user"
import userPermission from "../../../Utils/user_permission.js"
import GideProducts from "../../../FackApi/json/GideProducts.json"

export default {
  name: "GPathDashboard",
  components: {
    InviteUser,
    ChangeCounsellor,
    GPathSessionEdit,
    SendMail
  },
  data () {
    return {
      apiName: "gpath_session_list",
      cvCardTitle: "GPaths Dashboard",
      cvbtnAddNew: "Invite User to GPaths",
      cvbtnMangeAffCodes: "GPath Codes",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      showModelSendMail: false,
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "GPaths",
      cvSendMailModalHeader: "GPaths Send Mail",
      cvAddModalHeader: "Add GPaths",
      cvbtnModalCancel: "Cancel",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPaths Dashboard",
      csvTitle: "GPathsUserReport",
      showModelInviteUser: false,
      showModelGPathSessionEdit: false,
      showModelGPathSessionDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [],
      gpath_sessionObjList: [],
      gpath_sessionEditObj: null,
      filter: null,
      filterOn: [],
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        session_state: "",
        search_param: ""
      },
      counsellorObjList: [],
      showSessionCreationModal: false,
      showChangeCounsellorModal: false,
      GPathStateObj: {
        "1": "INITIATED",
        "2": "PERSONAL INFO COMPLETE",
        "3": "EDUCATION INFO COMPLETED",
        "4": "PAYMENT DONE",
        "5": "CAREER COMPLETED",
        "6": "SKILL COMPLETED",
        "7": "APTITUDE COMPLETED",
        "8": "EXTRACURRICULAR COMPLETED",
        "9": "ASSESSMENT COMPLETE [REPORT AWAITED]",
        "99": "REPORT GENERATED"
      }, // If changed here ensure changes are done in school dashboard gpaths as well
      cvModalTitle: "Invite to take GPaths Career Assessment",
      cvChangeCounsellorModalTitle: "Change Counsellor",
      sendMailObj: null,
      SendMailModules: SendMailModules,
      totalRows: 1,
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true,
      propGpathUser: {},
      GideProducts: GideProducts
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    // Setting views according to the user's role
    switch (this.userData.user_role) {
      case "USERROLE11111": // Gide Admin
      case "USERROLE11118": // Gide Counsellors
      case "USERROLE11116": // External counsellors(org_role)
        this.columns = [
          { label: "#", key: "id", class: "text-left align-text-top", sortable: true },
          { label: "User Name", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Parent Info", key: "parent_data", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Session Info", key: "session_state", class: "text-left align-text-top", sortable: true },
          { label: "Payments", key: "invoice_url", class: "text-left align-text-top w-300px", sortable: true },
          { label: "Assessment Date", key: "created_on", class: "text-left align-text-top", sortable: true }
        ]
        break
      case "USERROLE11114":
        this.columns = [
          { label: "#", key: "id", class: "text-left align-text-top", sortable: true },
          { label: "User Name", key: "user_name", class: "text-left align-text-top", sortable: true },
          { label: "Session Data", key: "session_state", class: "text-left align-text-top", sortable: true },
          { label: "Assessment Date", key: "created_on", class: "text-left align-text-top", sortable: true }
        ]
    }

    if (this.$route.params.user_id) {
      this.whereFilter.search_param = this.$route.params.user_id
    }

    this.setFilters()
    socialvue.index()
    await this.getDateFilter()

    // Counsellor or admin viewing the student's GPATH sessions
    if (this.$route.query.user_id && (this.userData.user_role == "USERROLE11118" || this.userData.user_role == "USERROLE11111")) {
      // Counsellor views the gpath_sessions of a single user
      this.whereFilter = { ...this.whereFilter, student_id: this.$route.query.user_id }
    }
    else if (this.userData.user_role == "USERROLE11118") {
      // Counsellor is viewing the gpath_session list
      this.whereFilter = { ...this.whereFilter, counsellor_view: true }
    }

    this.gpath_sessionList()

    // open up the invite model if 'invite_model' query parameter is passed as true
    if (this.$route.query.invite_model) {
      this.showSessionCreationModal = true
      window.history.pushState("GPathSessionList", "Title", "/gpath_session_list")
    }
  },
  methods: {
    /**
     * openUserProfile
     */
    openUserProfile (userId) {
      let url = `/user/${userId}`
      window.open(url, "_blank")
    },
    /**
     * openReport
     */
    openReport (url) {
      window.open(url, "_blank")
    },
    /**
     * showPaymentList
     */
    showPaymentList (user) {
      window.open(`/payTransactions_list/${user.user_id}`)
    },
    /**
     * openInvoice
     */
    async openInvoice (url) {
      window.open(url, "_blank")
    },
    /**
     * emitCloseInviteUser
     */
    emitCloseInviteUser () {
      this.gpath_sessionList()
      this.showSessionCreationModal = false
    },
    /**
     * emitCloseChangeCounselllor
     */
    emitCloseChangeCounselllor () {
      this.gpath_sessionList()
      this.showChangeCounsellorModal = false
    },
    /**
     * emitCloseGPathSendMailModal
     */
    async emitCloseGPathSendMailModal () {
      this.showModelSendMail = false
    },
    /**
      * searchFromBackend
      */
    searchFromBackend () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }

      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.currentBatchNo = 1
      this.gpath_sessionList()
    },
    /**
     * setFilters
     */
    async setFilters () {
      if (this.userData.user_role == "USERROLE11116") {
        return
      }

      // Get users of ORG along with Admin and GIDE COunselor
      const userListSearchResp = await User.userListSearch(this, { user_role: "USERROLE11118,USERROLE11111,USERROLE11116" })
      if (userListSearchResp.resp_status) {
        this.counsellorObjList = userListSearchResp.resp_data.data
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = { ...storeDate.dateRange }
      }
    },
    /**
     * Calling Function as per whereFilter
    */
    async getgpath_sessionDateWise () {
      this.gpath_sessionList()
      await this.setDateFilter()
    },
    /**
     * setCounsellor
    */
    async setCounsellor () {
      this.gpath_sessionList()
    },
    /**
     * showCreateSession
     */
    showCreateSession () {
      this.showSessionCreationModal = true
    },
    /**
     * showChangeCounsellor
     */
    showChangeCounsellor (gpathUser) {
      console.log(gpathUser)
      this.propGpathUser = {
        user_id: gpathUser.user_id,
        stu_name: gpathUser.user_name,
        stu_email: gpathUser.user_email,
        gps_id: gpathUser.gps_id,
        counsellor_id: gpathUser.counsellor_id,
        counsellor_name: gpathUser.counsellor_name,
        history: gpathUser.history
      }
      this.showChangeCounsellorModal = true
    },
    /**
     * goToAffiliateConsole
     */
    async goToAffiliateConsole () {
      if (this.userData.user_role == "USERROLE11116") {
        // Show the affiliate codes og gpaths only, when the user lands this page via GPATHS dashboard
        this.$router.push(`/affiliate_codes_dashboard?module_name=${GideProducts.GPATHS}`)
      }
      else {
        this.$router.push("/affiliate_codes_dashboard")
      }
    },
    /**
     * gpath_sendMail
     */
    async gpath_sendMail (type, gpathSessionObj) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_session_edit/" + this.gpath_sessionEditObj.gps_id)
        }
        else {
          if (gpathSessionObj.source && gpathSessionObj.source.includes("CSV")) {
            this.sendMailObj = {
              user_id: gpathSessionObj.user_id,
              user_name: gpathSessionObj.user_name,
              user_email: gpathSessionObj.user_email,
              cc_email: gpathSessionObj.parent_email,
              subject: { GPATH_REPORT: this.SendMailModules.GPATH_REPORT }
            }
          }
          else {
            this.sendMailObj = {
              user_id: gpathSessionObj.user_id,
              user_name: gpathSessionObj.user_name,
              user_email: gpathSessionObj.user_email,
              cc_email: gpathSessionObj.parent_email,
              subject: this.SendMailModules
            }
          }

          this.showModelSendMail = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_sendMail() and Exception:", err.message)
      }
    },
    /**
     * set Set Session State
    */
    async setSessionState () {
      this.gpath_sessionList()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * gpath_sessionList
     */
    async gpath_sessionList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage

        let gpathSessionListResp = await GPathSessions.gpath_sessionList(this, this.whereFilter)
        if (gpathSessionListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.gpath_sessionObjList = [ ...gpathSessionListResp.resp_data.data ]
          }
          else {
            this.gpath_sessionObjList = [ ...this.gpath_sessionObjList, ...gpathSessionListResp.resp_data.data ]
          }
          this.totalRows = this.gpath_sessionObjList.length
          this.showLoadMoreBtn = true
          // Adding the serial numbers:
          this.gpath_sessionObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.gpath_sessionObjList.length
        }
        else {
          this.currentBatchNo = this.currentBatchNo - 1
          this.showLoadMoreBtn = false
          if (gpathSessionListResp.resp_code == "ERR_DATA_NOT_FOUND" && this.showLoadMoreBtn) {
            // All data is loaded and loadMore button is clicked again
            this.showLoadMoreBtn = false
            this.toastMsg = "No more data to load"
            this.toastVariant = "warning"
            this.showToast = true
          }
        }
      }
      catch (err) {
        console.log("Exception occurred at gpath_sessionList() and Exception:", err.message)
      }
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.gpath_sessionList(true)
    },
    /**
     * gpath_sessionAdd
     */
    gpath_sessionAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_session_add")
        }
        else {
          this.showModelInviteUser = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_sessionAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_sessionEdit
     */
    gpath_sessionEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_session_edit/" + this.gpath_sessionEditObj.gps_id)
        }
        else {
          this.gpath_sessionEditObj = item
          this.showModelGPathSessionEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_sessionEdit() and Exception:", err.message)
      }
    },
    /**
     * showGPathSessionDeleteDialog
     */
    showGPathSessionDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGPathSessionDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGPathSessionDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_sessionDelete
     */
    async gpath_sessionDelete () {
      try {
        let gpathSessionDelResp = await GPathSessions.gpath_sessionDelete(this, this.delObj.gps_id)
        await ApiResponse.responseMessageDisplay(this, gpathSessionDelResp)

        if (gpathSessionDelResp && !gpathSessionDelResp) {
          this.showModelGPathSessionDelete = false
          return false
        }

        let index = this.gpath_sessionObjList.indexOf(this.delObj)
        this.gpath_sessionObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGPathSessionDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_sessionDelete() and Exception:", err.message)
      }
    },
    /**
     * closeInviteUserModal
     */
    closeInviteUserModal () {
      this.showModelInviteUser = false
    },
    /**
     * closeGPathSessionEditModal
     */
    closeGPathSessionEditModal () {
      try {
        this.showModelGPathSessionEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGPathSessionEditModal() and Exception:", err.message)
      }
    },
    /**
     * setGpathSessions
     */
    async setGpathSessions (sessionState, sessionDescription, userData) {
      let payload = {
        user_id: userData.user_id,
        gps_id: userData.gps_id,
        session_state: sessionState,
        session_state_desc: sessionDescription
      }

      let setGpathSessionResp = await GPathSessions.gpath_sessionEdit(this, payload)
      if (setGpathSessionResp && setGpathSessionResp.resp_status) {
        // Updating the store with the new state
        this.$store.dispatch("GpathSession/setGpathSession", { ...this.gpathSession, session_state: sessionState, session_state_desc: sessionDescription })
      }
    },
    /**
     * Generate the report
     */
    async generateReport (userData, useLambda = false) {
      this.showActionBtns = false

      const postData = {
        user_id: userData.user_id,
        user_email: userData.user_email,
        gps_id: userData.gps_id,
        showHTML: "0",
        filename: "gpaths",
        email_send_flag: "1"
      }

      if (useLambda) {
        // Generate report with lambda function
        GPathSessions.gpath_session_report_generate_lambda(this, postData)
        this.toastMsg = "Your report is being generated. It can take upto 5 mins to generate your report. Please note that a copy of the report will be sent to both your registered email address and the parent's email ID shared by you for your reference"
        this.toastVariant = "primary"
        this.showToast = true
      }
      else {
        // Generate report with api service
        let reportResp = await GPathSessions.gpath_session_report_generate(this, postData)
        if (!reportResp.resp_status) {
          this.toastMsg = "Oops! Looks like you have not completed the assessment. Please re-take the assessment. In case the problem persists please reach out to us on support@gide.ai"
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
    },
    /**
     *  open In Browser
     *  Append the classGrade to GPathData.GPATH_CAREER, GPathData.GPATH_SKILL, GPathData.GPATH_APTITUDE, GPathData.GPATH_COUNSELLOR if class <= 8
     */
    openInBrowser (type, sessionData) {
      let classGrade = sessionData.class_name <= 8 ? sessionData.class_name : ""

      let url = ""
      switch (type) {
        case "REPORT":
          url = `${process.env.VUE_APP_SERVER_URL}/gpath_report_test/${sessionData.user_id}/${sessionData.gps_id}`
          break
        case "CAREER":
          url = `${process.env.VUE_APP_SERVER_URL}/analyse_app_result/${GPathData.GPATH_CAREER}${classGrade}/${sessionData.user_id}?attempt=NA`
          break
        case "SKILL":
          url = `${process.env.VUE_APP_SERVER_URL}/analyse_app_result/${GPathData.GPATH_SKILL}${classGrade}/${sessionData.user_id}?attempt=NA`
          break
        case "APTITUDE":
          url = `${process.env.VUE_APP_SERVER_URL}/analyse_app_result/${GPathData.GPATH_APTITUDE}${classGrade}/${sessionData.user_id}?attempt=NA`
          break
        case "EXTRACURRICULAR":
          url = `${process.env.VUE_APP_SERVER_URL}/analyse_app_result/${GPathData.GPATH_COUNSELLOR}${classGrade}/${sessionData.user_id}?attempt=NA`
          break
        default:
      }

      window.open(url, "_blank")
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * getStateColorCode
     */
    getStateColorCode (statecode) {
      return `vertical-align: middle; color: #FFF; background: ${GPathData.StateInfoColors[statecode]} !important;`
    }
  }
}
</script>
